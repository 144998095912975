import { defineAction } from '_utils/redux'
import * as authenticationService from '_services/authentication'
import { updatedUserProfile } from '_/services/profiles'

export const GET_PROFILES = defineAction('GET_PROFILES')
export const LOGIN = defineAction('LOGIN')
export const GET_USER = defineAction('GET_USER')
export const UPDATE_USER = defineAction('UPDATE_USER')
export const UPDATE_USER_STATE = defineAction('UPDATE_USER_STATE')
export const RESET_PASSWORD = defineAction('RESET_PASSWORD')
export const LOGOUT = 'LOGOUT'
export const GET_VERIFICATION_TOKEN = defineAction('GET_VERIFICATION_TOKEN')
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'
export const AUTHENTICATE_TOKEN = defineAction('AUTHENTICATE_TOKEN')
export const ACCESS_HISTORY = defineAction('ACCESS_HISTORY')
export const PASSWORD_RECOVER = defineAction('PASSWORD_RECOVER')
export const DISABLE_APPLICATION = defineAction('DISABLE_APPLICATION')
export const ACCESS_CONTROL = defineAction('ACCESS_CONTROL')

export const loginUser = payload => dispatch => {
  const data = authenticationService.login(payload)
  dispatch({
    type: GET_PROFILES.ACTION,
    payload: data,
  })
  return data
}

export const setUserProfile = (key, payload) => async dispatch => {
  const data = await updatedUserProfile(key, payload)
  dispatch({
    type: LOGIN.ACTION,
    payload: async () => data,
  })
  return data
}

export const logout = () => ({ type: LOGOUT })

export const getUser = () => (dispatch, getState) => {
  const data = authenticationService.getUser(getState().user.authToken)
  dispatch({
    type: GET_USER.ACTION,
    payload: data,
  })

  return data
}

export const getVerificationToken = () => (dispatch, getState) => {
  const data = authenticationService.getVerificationToken(getState().user.authToken)
  dispatch({
    type: GET_VERIFICATION_TOKEN.ACTION,
    payload: data,
  })

  return data
}

export const setAuthToken = payload => dispatch => {
  dispatch({
    type: SET_AUTH_TOKEN,
    payload,
  })
}

export const updateUser = payload => (dispatch, getState) => {
  const data = authenticationService.updateUser(getState().user.authToken)(payload)
  dispatch({
    type: UPDATE_USER.ACTION,
    payload: data,
  })
  return data
}

export const updateUserState = payload => dispatch => {
  dispatch({
    type: UPDATE_USER_STATE,
    payload,
  })
}

export const resetPassword = payload => dispatch =>
  dispatch({
    type: RESET_PASSWORD.ACTION,
    payload: authenticationService.resetPassword(payload),
  })

export const authenticateToken = payload => async dispatch => {
  const data = await authenticationService.authenticateToken(payload)
  dispatch({
    type: AUTHENTICATE_TOKEN.ACTION,
    payload: async () => data,
  })
}

export const postAccessHistory = () => async (dispatch, getState) => {
  const data = await authenticationService.accessHistory(getState().user.authToken, {
    user: getState().user.id,
    access_type: 'WEB',
  })
  dispatch({
    type: ACCESS_HISTORY,
    payload: async () => data,
  })
  return data
}

export const pageAccessControl = (pageName, accessedBy) => async (dispatch, getState) => {
  const data = await authenticationService.pageAccessControl(getState().user.authToken, {
    page_name: pageName,
    accessed_by: accessedBy,
  })
  dispatch({
    type: ACCESS_CONTROL,
    payload: async () => data,
  })
  return data
}

export const passwordRecover = payload => async dispatch => {
  const data = await authenticationService.passwordRecover(payload)
  dispatch({
    type: PASSWORD_RECOVER,
    payload: async () => data,
  })
  return data
}

export const disableApplication = () => async dispatch => {
  const data = await authenticationService.disableApplication()
  dispatch({
    type: DISABLE_APPLICATION,
    payload: async () => data,
  })
  return data
}
