export const budgetTemplateCountSelector = state => state.budgetTemplate?.get('count')

export const budgetTemplatePageSelector = state => state.budgetTemplate?.get('page')

export const budgetTemplatePageSizeSelector = state => state.budgetTemplate?.get('pageSize')

export const budgetTemplateSelector = state => {
  const results = state.budgetTemplate?.get('results')
  const order = state.budgetTemplate?.get('orderBudgetTemplates')

  return order?.map(id => results.get(id?.toString()))
}

export const getBudgetTemplatetByIdSelector = id => state =>
  state.budgetTemplate?.getIn(['results', id])
