import { defineAction } from '_utils/redux'
import * as categoriesServices from '_services/categories'

export const GET_CATEGORIES = defineAction('GET_CATEGORIES')
export const GET_CATEGORIES_PARAMS = defineAction('GET_CATEGORIES_PARAMS')
export const GET_BUDGET_TEMPLATE = defineAction('GET_BUDGET_TEMPLATE')
export const GET_CATEGORIES_FILTERED = 'GET_CATEGORIES_FILTERED'

export const getCategories = () => (dispatch, getState) =>
  dispatch({
    type: GET_CATEGORIES.ACTION,
    payload: categoriesServices.getCategories(getState().user.authToken),
  })

export const getCategoriesParams = params => async (dispatch, getState) => {
  const data = await categoriesServices.getCategoriesParams(getState().user.authToken)(params)
  dispatch({
    type: GET_CATEGORIES_PARAMS.ACTION,
    payload: async () => data,
  })

  return data
}

export const getCategoriesFiltered = search => dispatch =>
  dispatch({
    type: GET_CATEGORIES_FILTERED,
    payload: search,
  })

export const getBudgetTemplate = params => async (dispatch, getState) => {
  const data = await categoriesServices.getBudgetTemplate(getState().user.authToken)(params)
  dispatch({
    type: GET_BUDGET_TEMPLATE.ACTION,
    payload: async () => data,
  })

  return data
}
